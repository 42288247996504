import { useState } from "react";

function App() {
  const [page, setPage] = useState(0);
  const PER_PAGE = 10;

  // const arr = new Array(100);
  const data = Array.from(Array(100).keys())
    .map((n) => n + 1)
    .map((n) => `Item ${n}`);

  const paginated = (page: number, perPage: number) => {
    const start = page * perPage;
    const end = start + perPage;

    return data.slice(start, end);
  };

  const nextPage = () => {
    if (page < 10) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const items = paginated(page, PER_PAGE);
  const total = data.length / PER_PAGE;

  return (
    <div className="max-w-lg mx-auto my-36">
      <div className="flex flex-col items-center gap-y-4">
        {items.map((item) => {
          return (
            <div className="shadow-md p-4 min-w-[350px] border border-slate-200 rounded-md">
              <div className="flex justify-center">
                <div className="font-semibold">{item}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center py-5 gap-4 items-center">
        <button
          className="font-bold text-lg"
          onClick={prevPage}
          disabled={!(page > 0)}
        >
          {"<"}
        </button>
        <div>
          {page + 1} of {total}
        </div>
        <button
          className="font-bold text-lg"
          onClick={nextPage}
          disabled={!(page < 9)}
        >
          {">"}
        </button>
      </div>
    </div>
  );
}

export default App;
